
import {computed, defineComponent, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "@/store";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import ScanPriorityLabel from "@/views/scan/ScanPriorityLabel.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "List",
  components: {DateTimeFormat, UserListSelect, BaseSelect, ScanPriorityLabel, KtDatatable, Entities},
  setup() {
    setCurrentPageBreadcrumbsWithParams('Scans',
      [
        {link: false, router: '', text: 'Scans'}
      ]
    )

    const filterObject = ref({priority: '', objectType: '', ownerId: ''})
    const page = computed(() => store.state.ScanModule.page);
    const tableHeader = ref([
      {
        name: "Date",
        key: "date",
        sortable: true,
      },
      {
        name: "Entity",
        key: "entity",
        sortable: true,
      },
      {
        name: "Owner",
        key: "owner",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Description",
        key: "desc",
        sortable: true,
      },
      {
        name: "Priority",
        key: "priority",
        sortable: true,
      },
    ]);
    return {
      ...LoadFilterObjects(Actions.LOAD_SCANS, filterObject.value, ['owner']),
      page,
      filterObject,
      ...LoadPanel(),
      tableHeader,

    }
  },
  methods: {
    onDiscard() {
      this.filterObject.priority = '';
      this.filterObject.objectType = '';
      this.filterObject.ownerId = '';
      this.paginationLoad()
    },
    onSearch() {
      this.updateFilterObject(this.filterObject)
    },
    toName(objectType) {
      switch (objectType) {
        case 'CASE' :
          return 'Case'
        case 'CLAIM' :
          return 'Claim'
        case 'INVOICE' :
          return 'Invoice'
      }
    }
  }
})
