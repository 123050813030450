
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "ScanPriorityLabel",
  props: {
    priority: {}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      switch (status) {
        case 'MEDIUM':
          data.value = {name: 'Medium', clazz: 'secondary'}
          break

        case 'HIGH':
          data.value = {name: 'High', clazz: 'danger'}
          break
      }
    }
    build(props.priority)
    watch(() => props.priority, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})
